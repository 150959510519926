import Vue from "vue";
import VueRouter from "vue-router";
import Cookie from "js-cookie";
import { getToken } from "../utils/tools";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Main.vue"),
    redirect: "/homes",
    children: [
      {
        path: "homes",
        name: "homes",
        component: () => import("../views/HomeView.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        // 测试页面，不需要放到页面上
        path: "path1",
        name: "path1",
        component: () => import("../views/PathOne.vue"),
        meta: {
          title: "供应商人员页",
        },
      },
      {
        // 测试页面，不需要放到页面上
        path: "path2",
        name: "path2",
        component: () => import("../views/PathTwo.vue"),
        meta: {
          title: "供应商费用查询",
        },
      },
      {
        // 测试页面，不需要放到页面上
        path: "details",
        name: "details",
        component: () => import("../views/Details.vue"),
        meta: {
          title: "详情页",
        },
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "登录页",
    },
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title ? to.meta.title : "久昌服务端";
//   // 判断token存在否
//   let token = Cookie.get("suppToken");
//   if (!token && to.name !== "login") {
//     next({ name: "login" });
//   } else if (token && to.name === "login") {
//     next({ name: "homes" });
//   } else {
//     // next
//     next();
//   }
// });

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "久昌服务端";
  let token = JSON.parse(localStorage.getItem("suppToken"));

  // console.log("进入路由守卫", to.name, from.name);
  if (!token && to.name !== "login") {
    // console.log("没有token并且页面不是登录页", to.name, from.name);
    next({ name: "login" });
  } else if (token && to.name === "login") {
    // console.log("有token并且页面是登录页", to.name, from.name);
    next({ name: "homes" });
  } else {
    // console.log("有token并且页面不是登录页", to.name, from.name);
    next();
  }
});

export default router;
