export function getToken(token) {
  
  return JSON.parse(localStorage.getItem(token))
}

export function setToken(tokenName,token) {
  return localStorage.setItem(tokenName, JSON.stringify(token))
}

export function removeToken(token) {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem(token)
}






// 返回当前年月日还有前7天的年月日
export function getDateStrings(days, operator) {
  const currentDate = new Date();
  const targetDate = new Date(currentDate);

  if (operator === "+") {
    targetDate.setDate(currentDate.getDate() + days);
  } else if (operator === "-") {
    targetDate.setDate(currentDate.getDate() - days);
  } else {
    return "Invalid operator";
  }

  const year = targetDate.getFullYear();
  const month =
    targetDate.getMonth() + 1 < 10
      ? `0${targetDate.getMonth() + 1}`
      : targetDate.getMonth() + 1;
  const date =
    targetDate.getDate() < 10
      ? `0${targetDate.getDate()}`
      : targetDate.getDate();

  return [`${year}-${month}-${date}`, currentDate.toISOString().slice(0, 10)];
}
//返回当前系统时间  yyyy-mm-dd hh:mm:ss
export function getCurrentTime(val) {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  // if (val === "年月日") {
  //   return `${year}-${month}-${day}`;
  // } else {
  //   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  // }

  if (val === "年月日") {
    return `${year}-${month}-${day}`;
  } else if (val === "年月") {
    return `${year}-${month}`;
  } else {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}

//过滤时间
export function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
  const day = dateTime.getDate().toString().padStart(2, "0");
  const hours = dateTime.getHours().toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");
  const seconds = dateTime.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
//返回本月的上一个月的时间，格式是 yyyy-MM
export function getLastMonth() {
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth() - 1;
  if (month < 0) {
    year--;
    month = 11;
  }
  return year + "-" + ("0" + (month + 1)).slice(-2);
}

//比较两个日期的大小
export function isAfter(a, b) {
  var a1 = new Date(a);
  var b1 = new Date(b);

  return a1 >= b1;
}
// 将数字型字符串转成数字
export function parseNumericString(str) {
  // 先尝试将字符串转换为浮点数
  let num = parseFloat(str);

  // 如果浮点数为 NaN，则说明字符串不是有效的数字型字符串，尝试将其转换为整数
  if (isNaN(num)) {
    num = parseInt(str, 10);
  }

  // 返回转换后的数字
  return num;
}

export function UTformatDate(dateTimeString) {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

/**
 * 以下三个方法时把Base64格式的文件转换成最开始的file形式对象的
 * base64ToBlob（）blobToFile（）base64ToFile（）
 * 三个缺一不可
 * * */
// 将 Base64 字符串转换为 Blob 对象
function base64ToBlob(base64String, fileList) {
  const byteCharacters = atob(base64String.split(",")[1]);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: fileList[0].type }); // 这里需要根据实际情况设置正确的 MIME 类型
  return blob;
}
// 将 Blob 对象转换为 File 对象
function blobToFile(blob, fileList) {
  blob.lastModifiedDate = new Date();
  blob.name = fileList[0].name; // 这里需要根据实际情况设置正确的文件名
  return new File([blob], blob.name, { type: blob.type });
}

// 将 Base64 字符串转换为 File 对象
export function base64ToFile(base64String, fileList) {
  const blob = base64ToBlob(base64String, fileList);
  const file = blobToFile(blob, fileList);
  return file;
}

// 把字符串对象转换成对象
export function parseCookieStringToObject(cookieString) {
  // 去掉字符串两端的空格，避免解析异常
  cookieString = cookieString.trim();

  // 判断 cookie 字符串是否以 '{' 开头，如果不是，说明该字符串不是一个 JSON 对象的序列化形式，直接返回 null。
  if (cookieString.charAt(0) !== "{") {
    return null;
  }

  try {
    // 尝试将 cookie 字符串解析成 JavaScript 对象
    var obj = JSON.parse(decodeURIComponent(cookieString));

    // 返回解析后的对象
    return obj;
  } catch (e) {
    // 解析失败，返回 null
    return null;
  }
}
